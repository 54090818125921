<template>
  <div class="elv-report-sources-container">
    <p class="elv-report-sources-title">{{ t('project.nav.accounts') }}</p>
    <div class="elv-report-sources-header">
      <el-tabs v-model="tabActiveName" type="border-card" class="elv-report-sources-tab" @tab-click="onChangeTab">
        <el-tab-pane
          v-if="
            entityDetail?.enableHourlyUpdate &&
            (!['MEMBER', ''].includes(currentEntityPermission?.role) || currentEntityPermission?.entityAccount?.view)
          "
          :label="t('project.nav.totalPosition')"
          name="entity-accounts-total-position"
          class="elv-report-detail-pane"
        />
        <el-tab-pane
          :label="t('project.nav.treasury')"
          name="entity-accounts-treasury"
          class="elv-report-detail-pane"
        />
        <el-tab-pane
          :label="t('valuation.assets.derivative')"
          name="entity-accounts-derivative"
          class="elv-report-detail-pane"
        />
        <el-tab-pane
          :label="t('project.nav.defiPosition')"
          name="entity-accounts-defi-position"
          class="elv-report-detail-pane"
        />
        <el-tab-pane
          v-if="!['MEMBER', ''].includes(currentEntityPermission?.role) || currentEntityPermission?.dataSource?.view"
          :label="t('project.nav.sources')"
          name="entity-accounts-sources"
          class="elv-report-detail-pane"
        />
        <el-tab-pane
          v-if="!['MEMBER', ''].includes(currentEntityPermission?.role) || currentEntityPermission?.counterparty?.view"
          :label="t('common.counterparty')"
          name="entity-accounts-contacts"
          class="elv-report-detail-pane"
        />
      </el-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { http } from '@/lib/http'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const { entityDetail } = storeToRefs(entityStore)

const loading = ref(false)
const tabActiveName = ref('entity-accounts-treasury')

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangeTab = (tab: any) => {
  if (
    [
      'entity-accounts-total-position',
      'entity-accounts-treasury',
      'entity-accounts-derivative',
      'entity-accounts-defi-position'
    ].includes(tab.props.name) &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.view
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (
    tab.props.name === 'entity-accounts-sources' &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.dataSource?.view
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (
    tab.props.name === 'entity-accounts-contacts' &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.view
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  router.push({
    name: tab.props.name
  })
}

watch(
  () => route.name,
  async () => {
    try {
      const routeName = String(route.name)
      if (accountStore.requestedList?.length) {
        http?.cancelRequest(accountStore.requestedList)
        accountStore.requestedList = []
      }
      switch (routeName) {
        case 'entity-accounts-treasury':
          tabActiveName.value = routeName
          emitter.emit('resetList')
          break
        case 'entity-accounts-contacts-named':
        case 'entity-accounts-contacts-unnamed':
          tabActiveName.value = 'entity-accounts-contacts'
          break
        default:
          tabActiveName.value = routeName
          break
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  accountStore.requestedList = []
})
</script>

<style lang="scss" scoped>
.elv-report-sources-container {
  width: 100%;
  height: 100%;
  position: relative;
}

:deep(.elv-confirm-info-header__title) {
  text-align: center;
}

.elv-report-sources-header {
  position: relative;
}

.elv-report-sources-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #000000;
  margin-left: 20px;
  margin-top: 16px;
}

.elv-report-sources-tab {
  margin-top: 8px;
}

@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
